










import { PropType } from 'vue'
import { StatusCode } from '@/services/HttpService/HttpService'

export default {
  name: 'ErrorComponent',
  props: {
    error: {
      type: Error as PropType<Error | undefined>,
      default: undefined,
    },
  },
  computed: {
    message() {
      return (
        this.error.message ||
        this.$t(
          `errorComponent.${this.error.statusCode || StatusCode.PageNotFound}`
        )
      )
    },
    statusCode() {
      return this.error.statusCode || StatusCode.PageNotFound
    },
  },
}
