
















import UserForm from '@/components/user/UserForm.vue'
import ErrorComponent from '@/components/common/ErrorComponent.vue'
import { fetchUser } from '@/modules/user/resources'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'

export default {
  name: 'User',
  components: {
    ErrorComponent,
    UserForm,
    LoadingSpinner,
  },
  data() {
    return {
      user: null,
      error: null,
      isUserLoading: true,
    }
  },
  async created() {
    try {
      this.user = await fetchUser(this.$route.params.id)
      this.isUserLoading = false
    } catch (error) {
      this.error = error
      this.isUserLoading = false
    }
  },
}
